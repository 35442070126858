import axios from '../node_modules/axios/index';
export const DOAMIN_PATH = 'https://pos-api.raidatrade.com/api/'

export async function category() {
    const response = await axios.get(DOAMIN_PATH + 'category');
    return response.data;
}

export async function categoryGetData(id: number) {
    const response = await axios.get(DOAMIN_PATH + `category/${id}`);
    return response.data;
}

export async function categoryCreateData(payload: any) {
    const response = await axios.post(DOAMIN_PATH + 'category', payload);
    if (response.status == 201) {
        return response.data;
    }
}

export async function CategoryUpdateData(id: number, data: any) {
    const response = await axios.put(DOAMIN_PATH + `category${id}`, data);
    return response.data;
}
export async function categoryDeleteData(id: number) {
    const response = await axios.delete(DOAMIN_PATH + `category/${id}`);
    return response.data;
}